<template>
  <div v-loading="loading" class="year-statistic">
    <SectionTitle fontSize="18" hideBtn>
      <template slot="title">
        <p style="font-size: 18px">年度統計</p>
        <p class="text-gray-60 text-sub" style="margin-left: 12px">計算範圍：當年度 1/1 00:00:00 ~ 隔年度 1/31 23:59:59</p>
      </template>
    </SectionTitle>
    <StatisticBlock v-if="features.useChashback || features.usePoint" :data="displayDataA" :prefix="null" />
    <StatisticBlock v-if="features.useClassTicket" :data="displayDataB" :prefix="null" />
    <StatisticBlock v-if="features.useCoupon" :data="displayDataC" :prefix="null" />
    <StatisticBlock v-if="features.useCouponExchange" :data="displayDataD" :prefix="null" />
  </div>
</template>

<script>
import StatisticBlock from '@/components/Report/StatisticBlock.vue'
import { usePermissions } from '@/use/permissions'
import { computed, defineComponent, reactive } from 'vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'YearStatistic',
  components: { StatisticBlock },
  props: {
    yearMetrics: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: true },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const features = reactive({
      usePoint: computed(() => checkAction('admin.shopPoint.page')),
      useChashback: computed(() => checkAction('admin.shopCashback.page')),
      useClassTicket: computed(() => checkAction('admin.classTicket.page')),
      useCouponExchange: computed(() => checkAction('admin.couponExchange.page')),
      useCoupon: computed(() => checkAction('admin.coupon.page')),
    })
    const displayDataA = computed(() => {
      const data = []
      if (features.usePoint) {
        data.push({ label: '點數-實際總發放數', value: getTotalCount('point'), unitLabel: '點' })
      }
      if (features.useChashback) {
        data.push({ label: '回饋金-實際總發放數', value: getTotalCount('cashback'), unitLabel: '元' })
      }
      return data
    })
    const displayDataB = computed(() => {
      return [
        { label: '堂票-總發放數', value: getTotalCount('classTicket'), unitLabel: '組' },
        { label: '堂票-總使用數', value: getUsedCount('classTicket'), unitLabel: `組 (${percentage(getTotalCount('classTicket'), getUsedCount('classTicket'))})` },
      ]
    })
    const displayDataC = computed(() => {
      return [
        { label: '使用券-總發放數', value: getTotalCount('coupon', 'coupon'), unitLabel: '張' },
        { label: '使用券-總使用數', value: getUsedCount('coupon', 'coupon'), unitLabel: `張 (${percentage(getTotalCount('coupon', 'coupon'), getUsedCount('coupon', 'coupon'))})` },
      ]
    })
    const displayDataD = computed(() => {
      return [
        { label: '兌換券-總發放數', value: getTotalCount('coupon', 'exchange'), unitLabel: '張' },
        { label: '兌換券-總使用數', value: getUsedCount('coupon', 'exchange'), unitLabel: `張 (${percentage(getTotalCount('coupon', 'exchange'), getUsedCount('coupon', 'exchange'))})` },
      ]
    })
    const percentage = (a, b) => {
      if (a === 0 && b === 0) {
        return '0%'
      }
      return (b / a * 100).toFixed(0) + '%'
    }
    const getTotalCount = (type, couponType = false) => {
      const data = get(props.yearMetrics, 'statistics')
      if (couponType) {
        return find(data, (item) => item.awardType === type && item.couponType === couponType) ? get(find(data, (item) => item.awardType === type && item.couponType === couponType), 'sumTotalCount') : 0
      }
      return find(data, (item) => item.awardType === type) ? get(find(data, (item) => item.awardType === type), 'sumTotalCount') : 0
    }
    const getUsedCount = (type, couponType = false) => {
      const data = get(props.yearMetrics, 'statistics')
      if (couponType) {
        return find(data, (item) => item.awardType === type && item.couponType === couponType) ? get(find(data, (item) => item.awardType === type && item.couponType === couponType), 'sumUsedCount') : 0
      }
      return find(data, (item) => item.awardType === type) ? get(find(data, (item) => item.awardType === type), 'sumUsedCount') : 0
    }

    return {
      displayDataA,
      displayDataB,
      displayDataC,
      displayDataD,
      features,
    }
  },
})

</script>

  <style scoped lang="postcss">

  </style>
