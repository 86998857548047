<template>
  <div class="data-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="會員姓名" prop="name" align="center" fixed="left" width="140px">
        <template slot-scope="scope">
          <router-link
            class="underline"
            :to="{
              name: 'MemberProfile',
              params: {
                id: get(scope.row, 'name.id'),
              },
            }"
          >
            {{ get(scope.row, 'name.UserInfo.name') }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="會員手機" prop="phone" align="center" fixed="left" width="140px" />
      <el-table-column label="事件類型" prop="eventType" align="center" />
      <el-table-column label="活動名稱" prop="eventName" align="center" />
      <el-table-column label="行銷項目類型" prop="marketingItemType" align="center" width="126px" />
      <el-table-column label="行銷項目名稱" prop="marketingItemName" align="center" width="126px" />
      <el-table-column label="發送數量" prop="count" align="center" />
      <el-table-column label="發送狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="selectedStatus(get(scope.row,'status'), 'tagType')">{{ selectedStatus(get(scope.row,'status'), 'label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="建立時間" prop="createdAt" align="center" width="120px" />
      <el-table-column label="預計發送時間" prop="scheduledSendAt" align="center" width="126px" />
      <el-table-column label="實際發送時間" prop="sendAt" align="center" width="126px">
        <template slot-scope="scope">
          <div v-if="get(scope.row,'sendAt') !== '-'">
            {{ get(scope.row,'sendAt') }}
          </div>
          <a v-else class="link" @click.prevent="show.reSend = true, selectRow = scope.row">重新發放</a>
        </template>
      </el-table-column>
      <el-table-column label="備註" prop="description" align="center" />
    </el-table>
    <WarningDialog
      v-if="show.reSend"
      title="重新發放"
      cancelBtnString="取消"
      btnString="確認"
      @confirm="onReSend"
      @close="show.reSend = false"
    >
      <template slot="body">
        <div>
          <p>您確定要重新發放行銷項目給該會員？</p>
        </div>
      </template>
    </WarningDialog>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { eventAwardConfig, eventTypeConfig, sendStatusConfig } from '@/config/marketing'
import { ResendEventPlaybookAwardRecord } from '@/api/eventMarketing'
import { get, map, find } from 'lodash'
import { formatDate } from '@/utils/date'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'EventRecordTable',
  components: { EmptyBlock, Tag, WarningDialog },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const selectRow = ref(null)
    const show = reactive({
      reSend: false,
    })
    const displayData = computed(() => {
      return map(get(props, 'tableData'), (i) => {
        const awardType = get(i, 'awardType')
        const awardConfig = find(eventAwardConfig, { value: awardType })
        const marketingItemLabel = get(awardConfig, 'label') || '-'

        const source = get(i, 'EventPlaybook.EventTemplateConfig.source')
        const typeConfig = find(eventTypeConfig, { value: source })
        const eventTypeLabel = get(typeConfig, 'label') || '-'
        const status = getStatus(get(i, 'isSend'), get(i, 'errorAt'))
        return {
          id: get(i, 'id'),
          ShopId: get(i, 'ShopId'),
          name: get(i, 'Member'),
          phone: get(i, 'Member.UserInfo.phone') || '-',
          eventType: eventTypeLabel,
          eventName: get(i, 'EventPlaybook.EventTemplateConfig.name') || '-',
          marketingItemType: marketingItemLabel,
          marketingItemName: get(i, 'awardName') || '-',
          count: `+${get(i, 'awardAmount')}`,
          status,
          createdAt: formatDate(get(i, 'createdAt')),
          scheduledSendAt: formatDate(get(i, 'scheduledSendAt')),
          sendAt: get(i, 'sendAt') ? formatDate(get(i, 'sendAt')) : '-',
          description: status === 'pending' ? '預計發送時間尚未開始' : get(i, 'errorMessage') || '-',
        }
      })
    })
    const getStatus = (isSend, errorAt) => {
      if (isSend === false && errorAt === null) {
        return 'pending'
      } else if (isSend === true) {
        return 'success'
      } else if (isSend === false && errorAt !== null) {
        return 'failed'
      }
    }
    const selectedStatus = (status, attr) => {
      return get(sendStatusConfig[status], attr)
    }
    const onReSend = async () => {
      console.log(selectRow.value)
      show.reSend = false
      const [res, err] = await ResendEventPlaybookAwardRecord({
        id: get(selectRow.value, 'id'),
        ShopId: get(selectRow.value, 'ShopId'),
      })
      if (err) window.$message.error(err)
      if (res) window.$message.success('發送成功!')
      emit('refresh')
    }

    return {
      get,
      selectRow,
      displayData,
      show,
      onReSend,
      selectedStatus,
    }
  },
})
</script>
<style lang="postcss" scoped>
.link {
  @apply text-primary-100 underline cursor-pointer
}
</style>
