import { render, staticRenderFns } from "./EventRecordTable.vue?vue&type=template&id=b3722730&scoped=true"
import script from "./EventRecordTable.vue?vue&type=script&lang=js"
export * from "./EventRecordTable.vue?vue&type=script&lang=js"
import style0 from "./EventRecordTable.vue?vue&type=style&index=0&id=b3722730&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3722730",
  null
  
)

export default component.exports